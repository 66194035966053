
<template>
   <div class="col-6 px-1 produto-linha">
      <div class="card mb-2 invert">
         <div class="card-body d-flex p-12 pe-1">
            <div class="icon icon-sm d-none d-lg-inline">
               <img src="@/assets/admin/images/iconeLoja.png" alt="Icon">
            </div>

            <div class="col align-self-center">
               <div class="row m-0">
                  <div class="col-6 p-0 align-self-center">
                     <div class="limitador font-15 mt-1">
                        <strong>Loja {{ preco.loja == null ? '-' : preco.loja }}</strong>
                     </div>
                  </div>
                  <div class="col-6 p-0 align-self-center text-center">
                     <div class="limitador font-15">
                        <small class="color-theme font-11">R$</small> {{ preco.preco }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Preco',
   props: ['preco']
}

</script>