<template>
   <div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 px-1 mb-3" :class="pesquisa.tipo == 'Sem estoque' ? 'col-md-9 col-xl-9' : 'col-sm-6 col-md-3 col-xl-3'">
                     <label><i class="far fa-stream color-theme font-12 me-1"></i> Tipo de pesquisa</label>
                     <v-select :options="['Nome do produto', 'Código', 'Marca', 'Categoria', 'Subcategoria', 'Sem estoque']" v-model="pesquisa.tipo" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 px-1 mb-3"  v-if="pesquisa.tipo == 'Categoria'">
                     <label><i class="far fa-file color-theme font-12 me-1"></i> Categoria</label>
                     <v-select :options="listaCategorias" v-model="pesquisa.categoria" label="categoria" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 col-xl-3 px-1 mb-3" v-if="pesquisa.tipo == 'Subcategoria'">
                     <label><i class="far fa-file color-theme font-12 me-1"></i> Categoria</label>
                     <v-select :options="listaCategorias" v-model="pesquisa.categoria" label="categoria" placeholder="Selecione uma opção" @input="buscarSubcategorias(pesquisa.categoria)" />
                  </div>
                  <div class="col-12 col-md-3 col-xl-3 px-1 mb-3" v-if="pesquisa.tipo == 'Subcategoria'">
                     <label><i class="far fa-copy color-theme font-12 me-1"></i> Subcategoria</label>
                     <v-select :options="subcategorias" v-model="pesquisa.subcategoria" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 col-xl-6 px-1 mb-3" v-if="pesquisa.tipo == 'Marca'">
                     <label><i class="far fa-bookmark color-theme font-12 me-1"></i> Marca</label>
                     <v-select :options="listaMarcas" v-model="pesquisa.marca" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 col-xl-6 px-1 mb-3" v-if="pesquisa.tipo == 'Nome do produto' || pesquisa.tipo == null || pesquisa.tipo == ''">
                     <label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome do produto</label>
                     <input type="text" class="form-control" v-model="pesquisa.nomeProduto" maxlength="50" v-focus placeholder="Nome do produto" @keyup.enter="buscarProdutos" />
                  </div>
                  <div class="col-12 col-sm-6 col-xl-6 px-1 mb-3" v-if="pesquisa.tipo == 'Código'">
                     <label><i class="fas fa-barcode color-theme font-12 me-1"></i> Código</label>
                     <input type="text" class="form-control" v-model="pesquisa.codigo" maxlength="50" v-focus placeholder="Código" @keyup.enter="buscarProdutos" />
                  </div>
                  <div class="col-5 col-md-1 px-1 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="buscarProdutos">
                        <i class="fas fa-search font-13"></i>
                     </button>
                  </div>
                  <div class="col-7 col-md-2 col-xl-2 px-1 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="abrirAddProduto">
                        <i class="fas fa-plus font-13 me-1"></i> Adicionar
                     </button>
                  </div>
                  <div class="col-12 text-end font-16 color-theme mb-2">
                     <i class="fal fa-th-large cursor-pointer" title="Quadrado" :class="produtosView != 'Quadrado' ? 'text-secondary' : ''" @click="toggleView('Quadrado')"></i>
                     <i class="fal fa-th-list ms-3 cursor-pointer" title="Linha" :class="produtosView != 'Linha' ? 'text-secondary' : ''" @click="toggleView('Linha')"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="resultado.length != 0">
         <div :class="produtosView == 'Linha' ? 'row m-0' : 'cards'">
            <produto v-for="(produto, index) in resultado" :key="index" :produto="produto" :index="index" :view="produtosView" :page="'PRODUTOS'" @abrirEditarProduto="abrirEditarProduto($event)" />
         </div>
      </div>

      <div class="col-12 py-5 text-center" v-else>Nenhum produto encontrado</div>

      <!-- modalSalvarProduto -->
      <div class="modal fade" id="modalSalvarProduto" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalSalvarProdutoLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalSalvarProdutoLabel" v-if="produto.id == null">Adicionar produto</h5>
                  <h5 class="modal-title" id="modalSalvarProdutoLabel" v-else>Editar produto</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="produtoConfig.toggleSalvar = false;"></button>
               </div>
               <div class="modal-body">
                  <div class="card mx-1 mb-2">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-12 col-md-7 mb-3 mb-md-0">
                              <label><i class="fas fa-barcode color-theme font-12 me-1"></i> Código</label> 
                              <input type="text" class="form-control" v-model="produto.codigo" maxlength="50" :disabled="produtoConfig.toggleSalvar" @keyup.enter="buscarProdutoCarolina(true)">
                           </div>
                           <div class="col-12 col-md-5 align-self-end">
                              <button class="btn bg-theme weight-600 w-100" @click="buscarProdutoCarolina(true)">
                                 <i class="far fa-file-import me-1"></i> Importar
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mx-1 mb-2">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-md-6 mb-3">
                              <label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome</label> 
                              <input type="text" class="form-control" v-model="produto.nome" maxlength="50">
                           </div>
                           <div class="col-md-6 mb-3">
                              <label><i class="far fa-ruler color-theme font-12 me-1"></i> Unidade de medida</label> 
                              <v-select :options="listaUnidadeMedida" placeholder="Selecione uma opção" v-model="produto.unidadeDeMedida" />
                           </div>
                           <div class="col-md-7 col-lg-9 mb-3 mb-md-0">
                              <label><i class="far fa-dollar-sign color-theme font-12 me-1"></i> Preço</label> 
                              <input type="text" class="form-control" v-model="produto.preco" v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']">
                           </div>
                           <div class="col-md-5 col-lg-3 align-self-end">
                              <div class="form-check d-inline-block ps-0">
                                 <input class="form-check-input" type="checkbox" id="flexCheckDefault" :checked="produto.precoExclusivo" v-model="produto.precoExclusivo">
                                 <label class="form-check-label pt-1" for="flexCheckDefault">Preço exclusivo</label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mx-1 mb-2">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-12 col-md-6 mb-3">
                              <label><i class="far fa-file color-theme font-12 me-1"></i> Categoria</label> 
                              <v-select :options="listaCategorias" label="categoria" placeholder="Selecione uma opção" v-model="produto.categoria" @input="buscarSubcategorias(produto.categoria)" />
                           </div>
                           <div class="col-12 col-md-6 mb-3">
                              <label><i class="far fa-copy color-theme font-12 me-1"></i> Subcategoria</label> 
                              <v-select :options="subcategorias" placeholder="Selecione uma opção" v-model="produto.subcategoria" />
                           </div>
                           <div class="col-12 col-md-6 mb-3">
                              <label><i class="far fa-bookmark color-theme font-12 me-1"></i> Marca</label> 
                              <v-select :options="listaMarcas" label="nome" placeholder="Selecione uma opção" v-model="produto.marca" />
                           </div>
                           <div class="col-12 col-md-6 mb-3">
                              <label><i class="far fa-box color-theme font-12 me-1"></i> Quantidade em estoque</label> 
                              <input type="text" class="form-control" v-model="produto.estoque" v-mask="'###############'">
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group file-area">
                                 <label for="images"><i class="fal fa-image color-theme font-12 me-1"></i> Foto</label>
                                 <input type="file" name="images" id="images" ref="images" accept=".jpg,.jpeg,.png,.webp" @change="onFileChange" @drop="onFileChange" @click="onFileChange" />
                                 <div class="file-dummy">
                                    <div class="default">Selecione ou arraste uma foto</div>
                                 </div>
                              </div>
                           </div>

                           <div class="col-lg-6 pt-3 mt-1 position-relative" v-if="image != null">
                              <div class="cropper-box">
                                 <cropper class="cropper" :src="image" :canvas="{ minHeight: 60, minWidth: 60, maxHeight: 300, maxWidth: 300 }" :stencil-props="{ minAspectRatio: 16/16, maxAspectRatio: 4/4 }" :auto-zoom="true" :backgroundClass="'bg-white'" @change="change" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mx-1" v-if="pesquisa.lojasPreco.length > 0">
                     <div class="card-body p-12">
                        <div class="row m-0">
                           <h6 class="px-2 mt-1 mb-2 pb-1"><i class="fal fa-comments-alt-dollar font-15 color-theme me-1"></i> Preços</h6>

                           <preco v-for="(preco, index) in pesquisa.lojasPreco" :key="index" :preco="preco" />
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarProduto" v-if="produtoConfig.toggleSalvar">Salvar</button>
                  <button type="button" class="btn bg-twitter text-white" @click="abrirAddProduto">Limpar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="produtoConfig.toggleSalvar = false;">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

import preco from '@/components/admin/produtos/Preco.vue'
import produto from '@/components/admin/produtos/Produto.vue'

export default {
	name: 'AdmProdutos',
   data: function () {
      return {
         produtosView: localStorage.produtosView == undefined ? 'Linha' : localStorage.produtosView,
         pesquisa: {'tipo': 'Nome do produto', 'categoria': null, 'subcategoria': null, 'nomeProduto': '', 'codigo': '', 'marca': null, 'lojasPreco': []},
         produto: {'id': null, 'nome': null, 'categoria': null, 'marca': null, 'foto': null, 'estoque': '0', 'codigo': null, 'preco': '0.00', 'unidadeDeMedida': null, 'quantidadeVendida': null, 'idComplemento': null, 'idProduto': null, 'precoExclusivo': false},
         produtoConfig: {'toggleSalvar': false},
         subcategorias: [],
         resultado: [],
         image: null
      }
   },
   computed: {
      ... mapState({
         listaMarcas: state => state.listaMarcas,
         listaCategorias: state => state.listaCategorias,
         listaUnidadeMedida: state => state.listaUnidadeMedida,
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      produto, preco
   },
   methods: {
      buscarSubcategorias : function (categoria) {
			this.listaCategorias.forEach(c => {
				if (c.categoria == categoria.categoria) {
					this.subcategorias = c.subcategorias

					if (this.produto.id == null) {
						this.pesquisa.subcategoria = c.subcategorias[0]
					}
				}
			});
      },
      buscarProdutos : function () {
         if (this.pesquisa.tipo == null || this.pesquisa.tipo == '') {
            this.pesquisa.tipo = 'Nome do produto'
         } 
         
         if ((this.pesquisa.tipo == 'Marca' && this.pesquisa.marca == null) || (this.pesquisa.tipo == 'Categoria' && this.pesquisa.categoria == null) || 
            (this.pesquisa.tipo == 'Subcategoria' && this.pesquisa.subcategoria == null)) {
            
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma '+ this.pesquisa.tipo
            });

            return
         }

			let valor = '';

			switch (this.pesquisa.tipo) {
				case 'Nome do produto':
					valor = this.pesquisa.nomeProduto
				break;
				case 'Código':
					valor = this.pesquisa.codigo
				break;
				case 'Marca':
					valor = this.pesquisa.marca
				break;
				case 'Categoria':
					valor = this.pesquisa.categoria.categoria
				break;
				case 'Subcategoria':
					valor = this.pesquisa.subcategoria
				break;
			}

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'adminproduct/searchproducts',
				headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            },
            params: {
               tipo: this.pesquisa.tipo,
               valor: valor
            }
         }).then(response => {
            this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      toggleView : function (view) {
         localStorage.produtosView = view
         this.produtosView = view
      },
      salvarProduto : function () {
         if (this.produto.nome == null || String(this.produto.nome).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Nome inválido!'
            })

            return
         } else if (this.produto.categoria == null || String(this.produto.categoria).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Categoria inválida!'
            })

            return
         } else if (this.produto.marca == null || String(this.produto.marca).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Marca inválida!'
            })

            return
         } else if (this.produto.estoque == null || String(this.produto.estoque).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Estoque inválido!'
            })

            return
         } else if (this.produto.codigo == null || String(this.produto.codigo).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Código inválido!'
            })

            return
         } else if (this.produto.preco == null || String(this.produto.preco).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Preço inválido!'
            })

            return
         } else if (this.produto.unidadeDeMedida == null || String(this.produto.unidadeDeMedida).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Unidade de medida inválida!'
            })

            return
         }

         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

			if (this.produto.categoria.categoria != undefined) {
				this.produto.categoria = this.produto.categoria.categoria
			}

			if (this.produto.marca.nome != undefined) {
				this.produto.marca = this.produto.marca.nome
			}

         this.$axios({
            method: 'post',
            url: this.urlRest +'adminproduct/saveproduct',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername,
					'Content-Type': 'application/json'
            },
				data: this.produto

         }).then(function () {
				$('#modalSalvarProduto').modal('hide')
            ref.produto = {'id': null, 'nome': null, 'categoria': null, 'marca': null, 'foto': null, 'estoque': '0', 'codigo': null, 'preco': '0.00', 'unidadeDeMedida': null, 'quantidadeVendida': null, 'idComplemento': null, 'idProduto': null, 'precoExclusivo': false}

            ref.$toast.fire({
               icon: 'success',
               title: 'Produto salvo!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 400) {
                  ref.$toast.fire({
							icon: 'error',
							title: 'Código já existente!'
						})
               } else if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
				this.buscarProdutos()
         })
      },
		abrirAddProduto : function () {
         this.pesquisa.lojasPreco = []
         this.image = null
         this.produtoConfig = {'toggleSalvar': false}
			this.produto = {'id': null, 'nome': null, 'categoria': null, 'marca': null, 'foto': null, 'estoque': '0', 'codigo': null, 'preco': '0.00', 'unidadeDeMedida': null, 'quantidadeVendida': null, 'idComplemento': null, 'idProduto': null, 'precoExclusivo': false}

			$('#modalSalvarProduto').modal('show')
		},
		abrirEditarProduto : function (produto) {
         this.pesquisa.lojasPreco = []
         this.produtoConfig = {'toggleSalvar': false}
			this.produto = {
				'id': produto.id, 
				'nome': produto.nome, 
				'categoria': produto.categoria, 
				'subcategoria': produto.subcategoria, 
				'marca': produto.marca, 
				'foto': produto.foto, 
				'estoque': produto.estoque, 
				'codigo': produto.codigo, 
				'preco': parseFloat(produto.preco).toFixed(2), 
				'unidadeDeMedida': produto.unidadeDeMedida,
				'quantidadeVendida': produto.quantidadeVendida,
				'idComplemento': produto.idComplemento,
				'idProduto': produto.idProduto,
            'precoExclusivo': produto.precoExclusivo
			}

         this.buscarProdutoCarolina(false)
			this.buscarSubcategorias(produto)
         this.image = null

			$('#modalSalvarProduto').modal('show')
		},
      buscarProdutoCarolina : function (reset) {
         if (String(this.produto.codigo).length >= 3) {
            let ref = this;
            this.$store.dispatch('alternarTelaCarregamento', true)

            this.$axios({
               method: 'get',
               url: this.urlRest +'adminproduct/searchincarolina',
               headers: {
                  'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
               },
               params: {
                  codigo: this.produto.codigo
               }
            }).then(response => {
               if (response.status != 204) {
                  if (reset) {
                     ref.produto.nome = response.data.nome.substr(0, 50)
                     ref.produto.preco = parseFloat(response.data.preco).toFixed(2)
                     ref.produto.unidadeDeMedida = response.data.unidadeDeMedida

                  } else {
                     ref.produto.nome = ref.produto.nome == null || ref.produto.nome.trim().length == 0 ? response.data.nome.substr(0, 50) : ref.produto.nome
                     ref.produto.preco = ref.produto.preco == null || ref.produto.preco.trim().length == 0 ? parseFloat(response.data.preco).toFixed(2) : ref.produto.preco
                     ref.produto.unidadeDeMedida = ref.produto.unidadeDeMedida == null || ref.produto.unidadeDeMedida.trim().length == 0 ? response.data.unidadeDeMedida : ref.produto.unidadeDeMedida
                  }

                  ref.produto.idProduto = response.data.idProduto
                  ref.produto.idComplemento = response.data.idComplemento
                  ref.pesquisa.lojasPreco = response.data.lojasPreco
                  ref.produtoConfig.toggleSalvar = true

               } else {
                  ref.produtoConfig.toggleSalvar = false
               }
            }).catch(function (error) {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     ref.$store.dispatch('deslogarAdmin')

                     ref.$toast.fire({
                        icon: 'warning',
                        title: 'Sessão expirada!'
                     });
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  }
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               this.$store.dispatch('alternarTelaCarregamento', false)
            });
         }
      },
      change : function ({ coordinates, canvas }) {
         coordinates;

         this.$refs.images.value = null;
			this.produto.foto = canvas.toDataURL();
		},
      onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;

         if (!files.length)
         return;

         this.createImage(files[0]);
      },
      createImage(file) {
         var reader = new FileReader();
         var vm = this;

         reader.onload = (e) => {
            vm.image = e.target.result;
         }

         reader.readAsDataURL(file);
      }
   }
}

</script>

<style scoped>

.fa-th-large, .fa-th-list {
   transform: translateY(-3px) scale(1.10);
}

.fa-th-large:hover, .fa-th-list:hover {
   transform: translateY(-3px) scale(1.20);
}

.form-check-input[type=checkbox] {
   border-radius: 0.25em;
   height: 20px;
   width: 20px;
   margin-right: 12px;
   margin-left: 0;
}

</style>